import React, {useState} from 'react';
import '@shopify/polaris/build/esm/styles.css'
import './App.css';
import { AppProvider } from "@shopify/polaris";
import { Context, Provider } from "@shopify/app-bridge-react";
import {Redirect } from '@shopify/app-bridge/actions';
import { Link, BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import RouterSwitch from 'router';
import AuthContext from 'auth/context';
import { authService } from 'auth';
import { authenticatedFetch } from '@shopify/app-bridge-utils';
import { API_KEY } from 'config/index';
import LoadingSkeleton from 'comps/skeleton';

function repLink({children, url = '', external, ref, ...rest}) {
  if (external) {
    rest.target = '_blank';
    rest.rel = 'noopener noreferrer';
    return (
      <a href={url} {...rest}>
        {children}
      </a>
    );
  }

  return (
    <Link to={url}>
      <div {...rest}>{children}</div>
    </Link>
  );
}

function App(){
  let [loading, setLoading] = useState(true);

  const authContext = { auth: authService, shop: authService.shop };

  const initalizeApp = (app)=>{
    const redirect = Redirect.create(app);
    authService.fetch = authenticatedFetch(app);
    authService.setRedirect({ provider: redirect, remote: Redirect.Action.REMOTE, app: Redirect.Action.APP });

    setLoading(false)
  }
  
  return (
    <AuthContext.Provider value={authContext}>
      <AppProvider linkComponent={repLink} features={{newDesignLanguage: true}}>
        <Provider
          config={{
            apiKey: API_KEY,
            host: authService.host,
            forceRedirect: true
          }}
        >
          <Context.Consumer>
            {
              app => {
                if(!app) return setLoading("error")
                initalizeApp(app)
              }
            }
          </Context.Consumer>
          <Router>
            {
              loading &&
              <Switch>
                <Route>
                    <LoadingSkeleton />
                </Route>
              </Switch>  ||
              <RouterSwitch loading={loading} />     
            }
          </Router>        
        </Provider>
      </AppProvider>
    </AuthContext.Provider>
  )
}

export default App;

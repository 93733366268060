import {Navigation} from '@shopify/polaris';
import {
    CircleDownMajor, CirclePlusMajor
} from "@shopify/polaris-icons/dist";
import { useLocation } from 'react-router-dom';
import React from 'react';

const Nav = ()=>{
        let location = useLocation();
        let main = location.pathname == '/' || location.pathname == '/login';

        return <Navigation location={location.pathname || '/'}>
        <Navigation.Section
        title="Booster theme"
        items={[
            {
                label: 'Theme Manager',
                icon: CircleDownMajor,
                url: '/',
                exactMatch: true,
                selected: main
            },
            {
                label: 'Theme Installer',
                icon: CirclePlusMajor,
                badge: 'NEW',
                url: '/booster/installer',
                exactMatch: true
            }
        ]}
        />
    </Navigation>
}

export default Nav;
import { INSTALL_URL } from 'config';

class Auth{
    constructor(){
        const url = new URL(window.location);
        this.host = url.searchParams.get('host')
        this.shop = new URL(`https://${atob(this.host)}`).host
    }

    setRedirect(redirect){
        this.redirect = redirect;
    }

    async ajax(url, config = {}){
        if(config.method == 'POST'){
            if(config.headers){
                config.headers["Content-Type"] = "application/json";
            }else{
                config.headers = {
                    "Content-Type": "application/json"
                }
            }
        }

        let res;

        try{
            res = await this.fetch(url, config);
        }catch(err){
            console.log(err)
            return {ok: false, error: 'Network error. Try again later or refresh this page.'};
        }

        if(!res.ok){
            if(res.status == 401){
                return {ok: false, error: 'Unauthenticated. Try relaunching the app from Shopify apps section.'};
            }
            try{
                let data = await res.json();
                let error = data.error || res.status;
                return {ok: false, error}
            }catch(e){
                return {ok: false, error: res.status};
            }
        }

        let data = await res.json();
        
        if(!data.ok){
            switch (data.error) {
                case 'BAD_ROLE':
                    return {ok: false, error: 'Insufficient permissions.'};

                case 'INVALID_HMAC':
                    return {ok: false, error: 'Application error. Try relaunching the app from Shopify apps section.'};

                case 'INVALID_LICENSE':
                    this.redirect.provider.dispatch(this.redirect.app, '/buy');
                    break;

                case 'SHOP_UNAUTHORIZED':
                    this.redirect.provider.dispatch(this.redirect.remote, INSTALL_URL); 
                    return {ok: false, error: 'Shop unauthorized. Reinstall the app.'}
            }

            if(data.error == 'SESSION_TOKEN_ERROR' || data.error == 'TOKEN_ERROR'){
                return {ok: false, error: 'Application error. Try relaunching the app from Shopify apps.'};
            }
        }
        return data;
    }
}

const authService = new Auth();

export {
    authService
}
// PRODUCTION
const API_URL = 'https://app.boostertheme.com/api';
const INSTALL_URL = 'https://app.boostertheme.com/appInstall/auth/inline?shop=';
const LICENSE_URL = 'https://theme.boostertheme.com';
const API_KEY = 'b217ace8d47d6577ed929abe0f405627';

// STAGING
// const API_URL = 'https://app-staging.boostertheme.com/api';
// const INSTALL_URL = 'https://app-staging.boostertheme.com/appInstall/auth/inline?shop=';
// const LICENSE_URL = 'https://theme.boostertheme.com';
// const API_KEY = 'bd625134acd593c03403f335be0e5825';

export {
    API_URL,
    INSTALL_URL,
    LICENSE_URL,
    API_KEY
}
import React, {useCallback, useState, useContext} from 'react';
import {Page, TextField, Banner, Button} from '@shopify/polaris';
import {TitleBar} from '@shopify/app-bridge-react';
import { useHistory, Redirect } from 'react-router-dom';
import AuthContext from 'auth/context';

export default function Password(){
    let {auth} = useContext(AuthContext);
    const [value, setValue] = useState('');
    const history = useHistory();
    const handleChange = useCallback((newValue) => setValue(newValue), []);

    const submitPassword = ()=>{
        auth.setPassword(value);
        history.goBack();
    }

    return <Page title="Password">
        <TitleBar
        title="Password"
        />
        <Banner title="This page is password protected" status="info">
            <p>The page you just tried to access requires a password.</p>
            <p>Enter it in the input field below.</p>
        </Banner>
        <br />
        <TextField label="Password" value={value} onChange={handleChange} />
        <br />
        <Button primary onClick={()=>submitPassword()}>
            Submit password
        </Button>
    </Page>
}
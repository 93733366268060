import React, {useEffect, useState, useContext} from 'react';
import { Layout, Card, Stack, TextContainer, Badge, Button, Heading, Subheading, Page, Spinner, Banner, Icon, Modal, DisplayText, Popover, ActionList } from '@shopify/polaris';
import {EditMinor, CircleTickOutlineMinor, DeleteMinor, TickMinor, EmbedMinor} from '@shopify/polaris-icons'
import AuthContext from 'auth/context';
import {API_URL} from 'config';
import './index.css';
import { useHistory } from 'react-router-dom';

const Activator = ({id, actionMenu, setActionMenu})=>{
    return <Button
        plain
        disclosure={actionMenu == id ? 'up' : 'down'}
        onClick={() => {
            let exp = actionMenu == id ? false : id;
            setActionMenu(exp);
        }}
    >
        More actions
    </Button>
}

const Updater = ()=>{
    const fCtrl = new AbortController();

    const [daysLeft, setDaysLeft] = useState('Loading');
    const [subscriptionStatus, setSubscriptionStatus] = useState('Loading');

    const [actionMenu, setActionMenu] = useState(false);

    const [themes, setThemes] = useState({});
    const [processing, setProcessing] = useState([]);
    const [update, setUpdate] = useState(false);

    const [loading, setLoading] = useState(true);
    const [gettingThemes, setGettingThemes] = useState(true);
    
    const [error, setError] = useState(false);
    const [disableUpdate, setDisableUpdate] = useState(false);
    const [modal, setModal] = useState(false);
    const [delModal, setDelModal] = useState(false);
    const [warning, setWarning] = useState(false);
    const [confirmation, setConfirmation] = useState(false);
    const [changedFiles, setChanged] = useState([]);

    const [limitBanner, setLimitBanner] = useState(false)


    const [boosterPublished, setBoosterPublished] = useState(true);
    const [boosterUpdated, setBoosterUpdated] = useState('Loading');
    const [booster5, setBooster5] = useState(true);
    
    let {auth} = useContext(AuthContext);
    let history = useHistory();

    const getLicense = async()=>{
        let res = await auth.ajax(API_URL + '/v1/license/remaining', {signal: fCtrl.signal});
        if(!res.ok){
            return
        }

        setSubscriptionStatus(res.subscribed);
        setDaysLeft(res.daysLeft);
    }

    const getThemes = async()=>{
        setGettingThemes(true);

        let res = await auth.ajax(API_URL + '/v1/updater/themes', {signal: fCtrl.signal});
        if(!res.ok){
            setLoading(false);
            setGettingThemes(false);
            return setError(res.error);
        }

        if(res.status == "processing"){
            return setTimeout(()=>getThemes(), 2500);
        }

        res.themes.booster.sort((a, b)=>{
            if(a.role == "main"){
                return -1
            }
            if(b.role == "main"){
                return 1
            }
            if(a.processing && b.processing){
                return 0;
            } else if(a.processing){
                return -1;
            } else if(b.processing){
                return 1;
            }
            if(a.version > b.version){
                return -1;
            } else if(b.version) {
                return 1;
            }
            return 0;
        })

        setThemes(res.themes);
        setUpdate({latest: res.latest});
        setLoading(false);
        setGettingThemes(false);

        let temp = []
        let _boosterPublished = false;
        let _booster5 = false;
        let _boosterUpdated = false;

        for(let theme of res.themes.booster){
            if(!_booster5 && parseInt(theme.version) == 5){
                _booster5 = true;
            }
            if(!_boosterUpdated && theme.version == res.latest){
                _boosterUpdated = true;
            }
            if(theme.processing){
                temp.push(theme.id);
            }
            if(!_boosterPublished && theme.role == "main"){
                _boosterPublished = true;
            }
        }

        if(!_boosterPublished){
            setBoosterPublished(false);
        }
        if(!_booster5){
            setBooster5(false);
        }
        if(!_boosterUpdated){
            setBoosterUpdated(false);
        } else {
            setBoosterUpdated(true);
        }

        for(let theme of res.themes.third){
            if(theme.processing){
                temp.push(theme);
            }
        }

        setProcessing(temp);

        if(temp.length){
            return setTimeout(()=>getThemes(), 2500);
        }

        if(res.themes.booster.length + res.themes.third.length >= 20){
            setDisableUpdate(true)
            setLimitBanner(true)
        } else {
            setDisableUpdate(false)
            setLimitBanner(false)
        }
    }

    const reqExtension = async ()=>{
        window.open('https://checkout.boostertheme.com/upgrade?token='+warning.token, '_blank');
        setConfirmation({theme: warning.theme});
        setWarning(false);
    }

    const reqUpdate = async(theme, ignore = false)=>{
        if(!ignore){
            let jwt, subscribed;
            if(!subscriptionStatus && parseInt(theme.version) < 5){
                try{
                    let token = await auth.ajax('https://app.boostertheme.com/api/v1/extend/token')
                    if(!token.ok){
                        setWarning(false);
                        return setError(token.error);
                    }
                    subscribed = token.subscribed;
                    jwt = token.token;
                }catch(err){
                    return setWarning(false);
                }

                return setWarning(
                    { theme, subscribed, token: jwt }
                )
            }
        }
        if(parseInt(theme.version) < 5){
            return history.push('/booster/installer');
        }
        setDisableUpdate(true);
        let res = await auth.ajax(API_URL + '/v1/updater/update', {
            method: 'POST',
            body: JSON.stringify({theme})
        })
        setDisableUpdate(false);
        if(!res.ok){
            return setModal(
                {
                    text: JSON.stringify(res.error),
                    title: 'An error occured'
                }
            )
        }
        setChanged(res.changed);
        if(!res.changed.length){
            return setModal({
                text: 'Updated intiated successfuly. All of your files and changes will be moved automatically. It can take up to a few minutes for Shopify to process the new theme and all of its changes. You can track the status directly for the Theme Updater panel.',
                title: 'Updated in progress - automatic transfer'
            })
        }
        if(!res.transfer){ 
            return setModal({
                text: 'Update is in progress, but sadly we won\'t be able to move over your files automatically. Here\'s a list of all the files that need to be changed;',
                title: 'Update in progress - manual transfer',
                action: 'changes'
            })
        }
        return setModal({
            text: 'Update is in progress, but sadly we won\'t be able to move ALL of your files automatically (some are still eligible for automatic transfers), since some of them have been changed internally. Here\'s a list of all the files and the internal changes',
            title: 'Update in progress - hybrid transfer',
            action: 'changes'
        })
    }

    useEffect(
        ()=>{
            getThemes();
            getLicense();
            return ()=>{fCtrl.abort()};
        },
        []
    )

    const deleteTheme = async(theme, showModal = true)=>{
        if(showModal){
            return setDelModal(theme);
        }
        let {id} = theme;
        setDisableUpdate(true)
        let res = await auth.ajax(API_URL + '/v1/theme/delete/' + id);
        if(!res.ok){
            setDisableUpdate(false)
            return setError(res.error);
        }
        setDisableUpdate(false)
        getThemes();
    }

    const publishTheme = async(id)=>{
        setDisableUpdate(true)
        let res = await auth.ajax(API_URL + '/v1/theme/publish/' + id);
        if(!res.ok){
            setDisableUpdate(false)
            return setError(res.error);
        }
        setDisableUpdate(false)
        getThemes();
    }

    return <Page title="Theme Manager" 
        secondaryActions={[
            {
                content: 'Refresh list', 
                disabled: gettingThemes,
                onAction: ()=>{
                    setLoading(true);
                    getThemes();
                }
            }
        ]
        } 
        primaryAction={
            {
                content: 'Install Booster Theme', 
                disabled: disableUpdate, 
                loading: disableUpdate,
                url: '/booster/installer'
            }
        }>
        <Layout>
            <Layout.Section oneHalf>
                <Card>
                    <Card.Section>
                        <Subheading>License</Subheading>
                        <Heading>{daysLeft != 'Loading' && (daysLeft + ' days left') || daysLeft}</Heading>
                    </Card.Section>
                    <Card.Section>
                        <Subheading>Subscription status</Subheading>
                        <Heading>{subscriptionStatus != 'Loading' && (subscriptionStatus && 'Subscribed' || 'Unsubscribed') || subscriptionStatus}</Heading>
                    </Card.Section>
                </Card>
            </Layout.Section>
            <Layout.Section oneHalf>
                <Card>
                    <Card.Section>
                        <Subheading>Latest version</Subheading>
                        <Heading>{update && update.latest || 'Loading'}</Heading>
                    </Card.Section>
                    <Card.Section>
                        <Subheading>Up to date</Subheading>
                        <Heading>{boosterUpdated != 'Loading' && (boosterUpdated && 'Latest version installed' || 'Update available') || boosterUpdated}</Heading>
                    </Card.Section>
                </Card>
            </Layout.Section>
            {
                limitBanner && 
                <Layout.Section>
                <Banner
                    title="Theme limit reached - 20 themes"
                    status="critical"
                    >
                    <p>Shopify Stores can't have more than 20 themes installed.</p>
                    <p>Please delete at least one theme in order to install or update BoosterTheme.</p>
                </Banner>
            </Layout.Section>
            }
            {/* Announcement examples */}
            {/* <Layout.Section>
                <Banner
                    title="BoosterTheme 6.0 - PRESETS!"
                    status="info"
                    action={{content: 'Install BoosterTheme 6.0', url: '/booster/new_installer', disabled: disableUpdate, loading: disableUpdate}}
                    secondaryAction={{content: 'Install BoosterTheme 5.1.9', disabled: disableUpdate, loading: disableUpdate, url: '/booster/installer'}}
                    >
                    <p>Our updater has been fully adjusted for the new OS2.0 and Booster 6.0 is the new default theme being installed with its default preset.</p>
                    <p>Preset migration has begun and will include new completely redesigned presets with all the benefits of OS2.0!</p>
                </Banner>
            </Layout.Section>
            <Layout.Section>
                <Banner
                    title="BoosterTheme OS2.0 Converter (Sections EVERYWHERE)"
                    status="info"
                    >
                    <p>All Booster 5.x themes can now be <strong>upgraded to Booster 6.0 and OS2.0 automatically.</strong>.</p>
                    <p>This will <strong>not override</strong> your current theme and you're free to test out and explore your upgraded 6.0 before you decide whether you'll publish it or not.</p>
                </Banner>
            </Layout.Section> */}
        </Layout>
        

        <div style={{margin: '24px 0px'}}>
            {!error &&
            <>
            {
                loading && <Spinner /> ||
                <>
            <Layout>
                    {processing && processing.length > 0 &&
                        <>
                            <Layout.Section>
                                <DisplayText>Currently installing</DisplayText>
                            </Layout.Section>
                            {
                                processing.map(
                                    (theme, i)=>{
                                        return <Layout.Section oneHalf>
                                            <Card sectioned>
                                            <Stack vertical>
                                                <TextContainer spacing="tight">
                                                    <Stack alignment="center" spacing="extraTight">
                                                        <Heading>{theme.name}</Heading>      
                                                        {theme.role == 'main' && <Badge status="info">LIVE</Badge>}
                                                    </Stack>
                                                </TextContainer>
                                                <Stack>
                                                    <Spinner size="small" />
                                                </Stack>     
                                            </Stack>
                                            </Card>
                                        </Layout.Section>
                                    }
                                )
                            }
                            {
                                processing.length % 2 == 1 &&
                                <Layout.Section oneHalf></Layout.Section>
                            }
                        </>
                    }


                    <Layout.Section>
                        <div style={processing.length > 0 && {marginTop: '24px'} || {}}>
                            <DisplayText>Booster Themes</DisplayText>
                        </div>
                    </Layout.Section>
                
                    {
                        themes.booster &&
                        (themes.booster.length > 0 && 
                        themes.booster.map(
                            (theme, i)=>{
                                if(theme.processing){
                                    return;
                                }
                                return <Layout.Section oneHalf>
                                    <Card sectioned>
                                    <Stack vertical>
                                        <TextContainer spacing="tight">
                                            <Stack alignment="center" spacing="extraTight">
                                                <Heading>{theme.name}</Heading>
                                                <Badge status="new">{theme.version}</Badge>      
                                                {theme.hasUpdate && <><Badge status="warning">UPDATE - {update.latest}</Badge></>}
                                                {theme.role == 'main' && <Badge status="info">LIVE</Badge>}
                                            </Stack>
                                        </TextContainer>
                                        <Stack alignment="center">
                                            {(theme.hasUpdate && <Button disabled={disableUpdate} onClick={()=>reqUpdate(theme)} primary>{parseInt(theme.version) == 5 ? `Upgrade to ${update.latest}`  : 'Update'}</Button> || <Button disabled icon={<Icon color="green" source={TickMinor} />}>Already up to date!</Button>)}
                                            <Popover active={actionMenu == theme.id} activator={<Activator id={theme.id} actionMenu={actionMenu} setActionMenu={setActionMenu} />} onClose={()=>setActionMenu(false)}>
                                            <ActionList
                                                items={[
                                                    {
                                                        content: 'Publish theme',
                                                        onAction: theme.role != "main" ? ()=>{
                                                            publishTheme(theme.id);
                                                            setActionMenu(false);
                                                        } : undefined, 
                                                        disabled: theme.role == "main",
                                                        icon: CircleTickOutlineMinor
                                                    },
                                                    {
                                                        content: 'Customize theme',
                                                        url: 'https://' + auth.shop + '/admin/themes/' + theme.id + '/editor',
                                                        external: true,
                                                        icon: EditMinor
                                                    },
                                                    {
                                                        content: 'Edit code',
                                                        url: 'https://' + auth.shop + '/admin/themes/' + theme.id,
                                                        external: true,
                                                        icon: EmbedMinor
                                                    },
                                                    {
                                                        content: 'Delete theme',
                                                        onAction: theme.role != "main" ? ()=>{
                                                            deleteTheme(theme);
                                                            setActionMenu(false);
                                                        } : undefined,
                                                        destructive: true,
                                                        icon: DeleteMinor,
                                                        disabled: theme.role == "main"
                                                    },
                                                ]}
                                            />
                                            </Popover>
                                        </Stack>     
                                    </Stack>
                                    </Card>
                                </Layout.Section>
                            }
                        )) ||
                        <Card sectioned>
                            You should really install Booster ASAP
                        </Card>
                    }
                    {
                        themes.booster && themes.booster.length % 2 == 1 &&
                        <Layout.Section oneHalf></Layout.Section>
                    }
            </Layout>
            <Layout>
                <Layout.Section>
                    <div style={{marginTop: '24px'}}>
                        <DisplayText>3rd party themes</DisplayText>
                    </div>
                </Layout.Section>
                {
                themes.third && themes.third.map((theme, i)=>{
                    if(theme.processing){
                        return;
                    }
                    return <Layout.Section oneHalf>
                            <div style={{opacity: 0.6}}>
                                <Card sectioned>
                                <Stack vertical>
                                    <TextContainer spacing="tight">
                                        <Stack alignment="center" spacing="extraTight"><Heading>{theme.name}</Heading><Badge status="default">3RD PARTY</Badge>
                                        {theme.role == 'main' && <Badge status="info">LIVE</Badge>}</Stack>
                                        <p>This is an unsupported theme.</p>
                                    </TextContainer>
                                    <Stack>
                                        <Button destructive onClick={()=>deleteTheme(theme)}>Delete theme</Button>
                                    </Stack>
                                </Stack>
                                </Card>
                            </div>
                        </Layout.Section>
                })
                }
                {
                    themes.third && themes.third.length % 2 == 1 &&
                    <Layout.Section oneHalf></Layout.Section>
                }
            </Layout>

            <Modal
                open={delModal != false}
                title={'Warning - This action is irreversible'}
                primaryAction={{
                    destructive: true,
                    content: 'Delete theme',
                    onAction: ()=>{
                        deleteTheme(delModal, false);
                        setDelModal(false);
                    },
                }}
                onClose={()=>setDelModal(false)}
                secondaryActions={{
                    content: 'Cancel',
                    onAction: ()=>{
                        setDelModal(false);
                    }
                }}
            >
                <Modal.Section>
                    <TextContainer>
                        Are you sure you want to delete theme <strong>{delModal.name} ({delModal.id})</strong>?<br/>
                        This action <strong>cannot be undone</strong>.
                    </TextContainer>
                </Modal.Section>
            </Modal>

            <Modal
                open={modal != false}
                title={modal && modal.title || 'BoosterTheme Updater'}
                primaryAction={{
                content: 'I understand',
                onAction: ()=>{
                    setChanged([]);
                    getThemes();
                    setModal(false);
                },
                }}
                onClose={()=>{
                    setChanged([]);
                    getThemes();
                    setModal(false)
                }}
            >
            <Modal.Section>
                <TextContainer>
                        {
                            modal && modal.text
                        }
                        {
                            modal && modal.action == 'changes' &&
                            <>
                            <p>File that have been modified, but are unable to be automatically moved over to the new theme.</p>
                            <ul>
                            {
                                changedFiles.map((file)=>{
                                    return <li className={'file--'+file.status}>{file.filename}</li>
                                })
                            }
                            </ul>
                            </>
                        }
                </TextContainer>
                </Modal.Section>
            </Modal>

            <Modal
                    open={warning != false}
                    title={"You're trying to update to a major release"}
                    primaryAction={!warning.subscribed && 
                        {
                            content: 'Upgrade now',
                            onAction: ()=>{
                                reqExtension();
                            },
                        } || 
                        {
                            content: 'Update now',
                            onAction: ()=>{
                                reqUpdate(warning.theme, true);
                                setWarning(false);
                            },
                        }}

                    secondaryActions={{
                        content: 'Cancel update',
                        onAction: ()=>{
                            setWarning(false);
                        },
                    }}
                    onClose={()=>setWarning(false)}
                >
                <Modal.Section>
                <TextContainer>
                        <p>You're trying to update to a major release of Booster (5.0.0+) which is a completely new theme and new rules apply.</p>
                        <p>Booster 5+ comes with new features built from scratch and has a new licensing policy.</p>
                        <p>Booster 5+ can be used as long as you have an active BoosterTheme license, otherwise your theme will be disabled and you won't be able to use it in your store.</p>
                        <p>To learn more about it check <a href="https://boostertheme.com/">new BoosterTheme pages</a></p>
                </TextContainer>
                </Modal.Section>
            </Modal>

            <Modal
                    open={confirmation != false}
                    title={"Have you read the previous warning?"}
                    primaryAction={
                        {
                            content: 'Update now',
                            onAction: ()=>{
                                setConfirmation(false);
                                reqUpdate(confirmation.theme, true);
                            },
                        }}

                    secondaryActions={{
                        content: 'Cancel update',
                        onAction: ()=>{
                            setConfirmation(false);
                        },
                    }}
                    onClose={()=>setConfirmation(false)}
                >
                <Modal.Section>
                <TextContainer>
                        Please confirm that you have read the previous warning and are aware that Booster 5.0+ is our new flagship product with a new licensing policy.
                </TextContainer>
                </Modal.Section>
            </Modal>

            </>
            }
            </> ||
            <>
                {
                    error == 'INVALID_LICENSE' && 
                    <Banner status="critical" title="Error" action={{content: 'Purchase a license', url: 'https://boostertheme.com/'}}>
                        You don't have a valid BoosterTheme license.
                    </Banner> ||
                    <Banner status="critical" title="Error">
                        {JSON.stringify(error)}
                    </Banner>
                }
            </>
        }
    </div>
    </Page>
}

export default Updater;
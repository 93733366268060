import React from 'react';
import {Switch, Route, useLocation} from 'react-router-dom';
import {Home, Updater} from 'pages';
import Nav from 'comps/navigation';
import {Frame, Page} from '@shopify/polaris';
import Installer from 'pages/installer';
import ComingSoon from 'pages/comingsoon';
import Token from 'pages/token';
import Password from 'pages/password';
import Buy from 'pages/buy';
import {useRoutePropagation} from '@shopify/app-bridge-react';
import InstallerNew from 'pages/installer-new';

function RouterSwitch(){
    useRoutePropagation(useLocation().pathname);

    return <Frame navigation={<Nav />}>
            <Switch>
                <Route exact={true} path='/'>
                    <Updater />
                </Route>
                <Route exact={true} path='/booster/manager'>
                    <Updater />
                </Route>
                <Route exact={true} path='/login'>
                    <Updater />
                </Route>
                <Route exact={true} path='/token/:token'>
                    <Token />
                </Route>
                <Route exact={true} path='/password'>
                    <Password />
                </Route>
                <Route exact={true} path='/buy'>
                    <Buy />
                </Route>
                <Route exact={true} path='/booster/new_installer'>
                    <InstallerNew />
                </Route>
                <Route exact={true} path='/booster/installer'>
                    <InstallerNew />
                </Route> 
                <Route>
                    <ComingSoon />
                </Route>
            </Switch>
        </Frame>
}

export default RouterSwitch;
import React, {useEffect, useState, useContext} from 'react';
import {TitleBar} from '@shopify/app-bridge-react';
import { Layout, Button, Page, Spinner, Banner, Icon, Card, Stack } from '@shopify/polaris';
import {TickMinor} from '@shopify/polaris-icons'
import AuthContext from 'auth/context';
import {API_URL} from 'config';
import {useHistory} from 'react-router-dom';

const InstallerNew = ()=>{
    const [preset, setPreset] = useState(false);
    const [loading, setLoading] = useState(true);
    const [presets, setPresets] = useState([]);
    const [disable, setDisabled] = useState(false);
    const [error, setError] = useState(false);
    let {auth} = useContext(AuthContext);
    let history = useHistory();

    const getPresets = async()=>{
        let res = await auth.ajax(API_URL + '/v1/installer/new_presets');
        if(!res.ok){
            setLoading(false);
            setError(res.error);
            return;
        }
        setPresets(res.presets);
        setLoading(false);
    }

    const installTheme = async()=>{
        setDisabled(true);
        let rep = await auth.ajax(API_URL + '/v1/installer/install', {
            method: 'POST',
            body: JSON.stringify({preset: preset == -1 ? false : preset})
        })
        if(rep.ok){
            setDisabled(false);
            history.push('/');
        }
    }

    useEffect(
        ()=>{
            getPresets();
        },
        []
    )

    return <Page title="Theme Installer - OS2.0" primaryAction={
        !error && 
        (
            preset === false &&
            <Button primary disabled={true} >Choose a template to continue.</Button> ||
            <Button primary disabled={disable} onClick={()=>installTheme()}>Install Booster Theme Preset</Button>
        )
    }>
        {loading && <Spinner /> || (
            !error &&
        <>
        <div style={{marginBottom: '20px'}}>
            <Banner
                title="Select a demo"
                status="info"
                >
                <p>
                    Choose from one of the stores shown below to get a headstart, or start from scratch with a blank template and let your designer skills show.
                </p>
            </Banner>
        </div>
        <Layout>
            {
                presets && presets.map((v)=>{
                    let showDemo = v.demo_store != '' && <Button plain external url={v.demo_store}>Show a demo</Button>  || '';
                    return <Layout.Section oneThird>
                            <Card title={v.name} sectioned>
                                <img
                                    alt=""
                                    width="100%"
                                    height="100%"
                                    style={{
                                    objectFit: 'cover',
                                    objectPosition: 'center',
                                    marginBottom: '20px'
                                    }}
                                    src={v.thumbnail}
                                />
                                <Stack vertical={false} spacing="loose" alignment="center">
                                    {preset == v.id && <Button disabled icon={<Icon color="green" source={TickMinor} />}>Selected</Button> || <Button onClick={()=>setPreset(v.id)} primary>Select this demo</Button>}
                                    {showDemo}
                                </Stack>
                            </Card>
                        </Layout.Section>
                })
            }
            <Layout.Section oneThird>
                <Card title="Default template" sectioned>
                    {preset == -1 && <Button disabled icon={<Icon color="green" source={TickMinor} />}>Selected</Button> || <Button onClick={()=>setPreset(-1)} primary>Select this demo</Button>}
                </Card>
            </Layout.Section>
        </Layout>
        </> ||
        <Banner status="critical" title="Error">
            {JSON.stringify(error)}
        </Banner>
        )
        }
    </Page>
}

export default InstallerNew;
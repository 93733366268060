import React, {useContext} from 'react';
import {useParams, Redirect} from 'react-router-dom';
import AuthContext from 'auth/context';

export default function Token(){
    let { token } = useParams();
    let {auth} = useContext(AuthContext);
    auth.setAccess(token);
    
    return <Redirect to="/" />
}
import React from 'react';
import {Page, Banner, Link, Button, Stack} from '@shopify/polaris';
import {TitleBar} from '@shopify/app-bridge-react';

export default function Buy(){
    return <Page title="Buy BoosterTheme">
        <TitleBar
        title="Missing license"
        />
        <Banner title="This app requires a license" status="info">
            <p>This app requires a valid BoosterTheme license.</p>
            <p>If you already own a BoosterTheme license, you can activate it on this store using user's panel.</p>
            <br />
            <Stack spacing="tight" alignment="center">
                <Button url="https://checkout.boostertheme.com/one-license" external>Purchase BoosterTheme</Button>
                <Button plain url="https://users.boostertheme.com" external>Visit User's Panel</Button>
            </Stack>
        </Banner>
    </Page>
}